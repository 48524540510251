import React from 'react'
import './logo.scss'

interface Props {
  fill?: string
  displayText?: boolean
  className?: string
  textColor?: string
}
export function Logo(props: Props) {
  const { fill = '#2B6CB0', displayText = true, className = '', textColor = 'text-blue-700' } = props
  return (
    <a href="/">
      <div className={`logo inline-flex ${className}`}>
        <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M72.9449 4.63315V33.5594L66.9478 27.8568V6.41004H6.602V66.2873H28.2582L34.172 72.1966H4.8112C3.70666 72.1966 2.64737 71.7612 1.86634 70.9862C1.08532 70.2113 0.646545 69.1602 0.646545 68.0642V4.63315C0.646545 3.53719 1.08532 2.48611 1.86634 1.71115C2.64737 0.936195 3.70666 0.500827 4.8112 0.500827H68.697C69.2508 0.489833 69.8014 0.588598 70.3163 0.791339C70.8312 0.994079 71.3002 1.29672 71.6958 1.68154C72.0914 2.06636 72.4056 2.52563 72.6201 3.03245C72.8346 3.53927 72.945 4.08346 72.9449 4.63315Z"
            fill={fill}
          />
          <path
            d="M72.9448 40.2136V68.0241C72.9448 69.1201 72.506 70.1712 71.725 70.9461C70.944 71.7211 69.8847 72.1565 68.7801 72.1565H52.538L46.4576 66.2886V58.0239L54.7869 66.2886H66.6978V45.9576H40.6688L46.4576 40.2136H72.9448Z"
            fill={fill}
          />
          <path
            d="M60.326 15.2544V33.8912L54.5371 28.106V18.7669H19.0543V53.9743H27.8417L33.5889 59.7182H15.5143C15.2066 59.7293 14.8998 59.6787 14.6122 59.5695C14.3246 59.4603 14.0622 59.2946 13.8406 59.0825C13.619 58.8703 13.4427 58.616 13.3225 58.3347C13.2022 58.0534 13.1403 57.751 13.1405 57.4454V15.2544C13.1288 14.942 13.1822 14.6306 13.2974 14.3395C13.4126 14.0485 13.587 13.7842 13.8098 13.5631C14.0326 13.3421 14.299 13.169 14.5923 13.0547C14.8856 12.9404 15.1995 12.8874 15.5143 12.899H58.0354C58.3434 12.8988 58.6482 12.9602 58.9317 13.0796C59.2151 13.1989 59.4715 13.3738 59.6853 13.5937C59.8991 13.8135 60.066 14.0739 60.1761 14.3593C60.2862 14.6446 60.3372 14.9491 60.326 15.2544Z"
            fill={fill}
          />
        </svg>
        {displayText && (
          <div className={`logo-text font-semibold text-xl tracking-tight ${textColor} flex items-center ml-2`}>
            GGJungle
          </div>
        )}
      </div>
    </a>
  )
}
